import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    // {
    //     path: 'dashboard',
    //     title: 'Dashboard',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'dashboard',
    //     submenu: []
    // },

    {
        path: '',
        title: 'Users',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user',
        submenu: [
        {
            path: '/users',
            title: 'User List',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user',
            submenu: []
        },
        {
            path: '/users/new-user',
            title: 'Add User',
            iconType: 'nzIcon',
            iconTheme: 'outline',
            icon: 'user-add',
            submenu: []
        }
    ]
    },
    {
        path: '/events',
        title: 'Events List',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'calendar',
        submenu: []
      
    },
    // {
    //     path: '',
    //     title: 'Multi Level Menu',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'appstore',
    //     submenu: [
    //         { 
    //             path: '',
    //             title: 'Level 1', 
    //             iconType: '', 
    //             icon: '',
    //             iconTheme: '',
    //             submenu: [
    //                 {
    //                     path: '',
    //                     title: 'Level 2',
    //                     iconType: 'nzIcon',
    //                     iconTheme: 'outline',
    //                     icon: '',
    //                     submenu: []
    //                 }    
    //             ] 
    //         }
    //     ]
    // }
]    