// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  service_query_url: "https://jammah.adpediafilms.com/api",
  domain: "https://jammah.adpediafilms.com/",
  img_url:"https://jammah.adpediafilms.com/public/",
  // service_query_url: "http://127.0.0.1:8000/api",
  // img_url: "http://127.0.0.1:8000/",
  // domain: "http://127.0.0.1:8000/",

  cookie: {
    domain: "localhost",
    secure: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
