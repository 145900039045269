import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    } ,
    { path: 'events', loadChildren: () => import('../../modules/events/events.module').then(m => m.EventsModule) },
    { path: 'new-event', loadChildren: () => import('../../modules/events/events.module').then(m => m.EventsModule) },
    { path: 'event-view', loadChildren: () => import('../../modules/events/events.module').then(m => m.EventsModule) },
    { path: 'event-edit', loadChildren: () => import('../../modules/events/events.module').then(m => m.EventsModule) },
    { path: 'users', loadChildren: () => import('../../modules/users/users.module').then(m => m.UsersModule) },
    { path: 'new-user', loadChildren: () => import('../../modules/users/users.module').then(m => m.UsersModule) },
 

];